<template>
    <div>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.aeiUpdates.$errors.length }"
                    :label="$t('forms.aeiUpdates')"
                    label-for="desc"
                >
                    <b-form-radio
                        v-model="aeiUpdates"
                        :name="$t('forms.aeiUpdates')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="aeiUpdates"
                        :name="$t('forms.aeiUpdates')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.aeiUpdates.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group class="row" :label="$t('forms.comment')" label-for="desc">
                    <b-form-input name="comment"/>
                </b-form-group>
            </b-col>
            <b-col></b-col>
        </b-row>
        <b-row>
            <b-col
            >
                <b-form-group
                    :class="{ row, error: v$.stationEmail.$errors.length }"
                    :label="$t('forms.stationEmail')"
                    label-for="desc"
                >
                    <b-form-radio
                        v-model="stationEmail"
                        :name="$t('forms.stationEmail')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="stationEmail"
                        :name="$t('forms.stationEmail')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.stationEmail.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col
            >
            <b-col
            >
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="desc"
                >
                    <b-form-input name="comment"/>
                </b-form-group
                >
            </b-col>
            <b-col></b-col>
        </b-row>
        <b-row>
            <b-col
            >
                <b-form-group
                    :class="{ row, error: v$.businessEmail.$errors.length }"
                    :label="$t('forms.businessEmail')"
                    label-for="desc"
                >
                    <b-form-radio
                        v-model="businessEmail"
                        :name="$t('forms.businessEmail')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="businessEmail"
                        :name="$t('forms.businessEmail')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.businessEmail.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col
            >
            <b-col
            >
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="desc"
                >
                    <b-form-input name="comment"/>
                </b-form-group
                >
            </b-col>
            <b-col></b-col>
        </b-row>
        <b-row>
            <b-col
            >
                <b-form-group
                    :class="{ row, error: v$.customersWorkPack.$errors.length }"
                    :label="$t('forms.customersWorkPack')"
                    label-for="desc"
                >
                    <b-form-radio
                        v-model="customersWorkPack"
                        :name="$t('forms.customersWorkPack')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="customersWorkPack"
                        :name="$t('forms.customersWorkPack')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.customersWorkPack.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col
            >
            <b-col
            >
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="desc"
                >
                    <b-form-input name="comment"/>
                </b-form-group
                >
            </b-col>
            <b-col></b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.assignedCwos.$errors.length }"
                    :label="$t('forms.assignedCwos')"
                    label-for="desc"
                >
                    <b-form-radio
                        v-model="assignedCwos"
                        :name="$t('forms.assignedCwos')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="assignedCwos"
                        :name="$t('forms.assignedCwos')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.assignedCwos.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group class="row" :label="$t('forms.comment')" label-for="desc">
                    <b-form-input name="comment"/>
                </b-form-group>
            </b-col>
            <b-col></b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.sufficientManpower.$errors.length }"
                    :label="$t('forms.sufficientManpower')"
                    label-for="desc"
                >
                    <b-form-radio
                        v-model="sufficientManpower"
                        :name="$t('forms.sufficientManpower')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="sufficientManpower"
                        :name="$t('forms.sufficientManpower')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.sufficientManpower.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col
            >
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="desc"
                >
                    <b-form-input name="comment"/>
                </b-form-group
                >
            </b-col>
            <b-col></b-col>
        </b-row>
        <b-row>
            <b-col
            >
                <b-form-group
                    :class="{ row, error: v$.requiredParts.$errors.length }"
                    :label="$t('forms.requiredParts')"
                    label-for="desc"
                >
                    <b-form-radio
                        v-model="requiredParts"
                        :name="$t('forms.requiredParts')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="requiredParts"
                        :name="$t('forms.requiredParts')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.requiredParts.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col
            >
            <b-col>
                <b-form-group class="row" :label="$t('forms.comment')" label-for="desc">
                    <b-form-input name="comment"/>
                </b-form-group
                >
            </b-col>
            <b-col></b-col>
        </b-row>
        <b-row>
            <b-col
            >
                <b-form-group
                    :class="{ row, error: v$.requiredTooling.$errors.length }"
                    :label="$t('forms.requiredTooling')"
                    label-for="desc"
                >
                    <b-form-radio
                        v-model="requiredTooling"
                        :name="$t('forms.requiredTooling')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="requiredTooling"
                        :name="$t('forms.requiredTooling')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.requiredTooling.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col
            >
            <b-col
            >
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="desc"
                >
                    <b-form-input name="comment"/>
                </b-form-group
                >
            </b-col>
            <b-col></b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.issue.$errors.length }"
                    :label="$t('forms.issue')"
                    label-for="desc"
                >
                    <b-form-radio v-model="issue" :name="$t('forms.issue')" value="Yes">
                        Yes
                    </b-form-radio>
                    <b-form-radio v-model="issue" :name="$t('forms.issue')" value="No">
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.issue.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col
            >
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="desc"
                >
                    <b-form-input name="comment"/>
                </b-form-group
                >
            </b-col>
            <b-col></b-col>
        </b-row>
        <b-row>
            <b-col
            >
                <b-form-group
                    :class="{ row, error: v$.timeLanding.$errors.length }"
                    :label="$t('forms.timeLanding')"
                    label-for="desc"
                >
                    <b-form-radio
                        v-model="timeLanding"
                        :name="$t('forms.timeLanding')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="timeLanding"
                        :name="$t('forms.timeLanding')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.timeLanding.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col
            >
            <b-col
            >
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="desc"
                >
                    <b-form-input name="comment"/>
                </b-form-group
                >
            </b-col>
            <b-col></b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group class="row" :label="$t('forms.remark')" label-for="desc">
                    <b-form-input name="remark"/>
                </b-form-group>
            </b-col>
            <b-col></b-col>
            <b-col></b-col>
        </b-row>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            aeiUpdates: {required},
            stationEmail: {required},
            businessEmail: {required},
            customersWorkPack: {required},
            assignedCwos: {required},
            sufficientManpower: {required},
            requiredParts: {required},
            requiredTooling: {required},
            issue: {required},
            timeLanding: {required},
        };
    },
    data() {
        return {
            aeiUpdates: null,
            stationEmail: null,
            businessEmail: null,
            customersWorkPack: null,
            assignedCwos: null,
            sufficientManpower: null,
            requiredParts: null,
            requiredTooling: null,
            issue: null,
            timeLanding: null,
        };
    },
};
</script>

<style>
</style>
